import React from 'react'
import { NewProductIds, UniversalNavV2 } from 'tn-components-library'
import Config from '../../profiles'
import ViewAs from './ViewAs'
import './styles.css'
import KeyCloakAuthInstance from '../../keyAuth'
import { getRoleDetails, isStaffingEnabled } from '../../utils'

const SideNav = (props) =>{
  const [isViewAsModalOpen, setViewAsModalOpen] = React.useState(false)
    const insightsData = props.insights
    const onPasswordChangeHandler = () => {
      let url = `${KeyCloakAuthInstance.getUpdatePasswordURL()}`
      window.open(url, '_self');
    }
    const logout = () => {
      KeyCloakAuthInstance.logout()
    }
    const menuClickCallback = (menuItem) =>{
      console.log(menuItem)
      switch (menuItem.id) {
        case 'viewas': {
          setViewAsModalOpen(true)
          break
        }
        case 'logout': {
          logout()
          break
        }
        default: {
          break
        }
      }
    }
    const roles = getRoleDetails(window.customrole?.UserContext?.geminiroles)
    const args = {
        roles,
        planAccess: window.customrole.UserContext.tnp2xenabled,
        recruitAccess: window.customrole.UserContext.tnr2xenabled,
        customRoleAccess: true,
        dataFeedAccess: window.customrole.UserContext.dataFeedEnabled,
        adminAcess: window.customrole.UserContext.adminenabled,
        activeProduct: NewProductIds.CUSTOM_ROLE,
        activeMenu: NewProductIds.CUSTOM_SOLUTION,
        isAlertsEnabled: window.customrole.UserContext.isAlertsEnabled,
        isStaffing: isStaffingEnabled(roles),
        ssoEnabled: window.customrole.UserContext.ssoenabled,
        urls: {
          customrole: '/',
          insight: `${Config.homeURL}/learnmore/insights`,
          learnmore: `${Config.homeURL}/learnmore`,
          plan: `${Config.plan2xURL}/#`,
          recruit: `${Config.recuritURL}/#`,
          datafeeds: Config.dataFeedURL,
          admin: Config.tnAdmin,
          myaccount: `${Config.homeURL}/home/myaccount`,
          password: () => onPasswordChangeHandler(),
          home: Config.homeURL,
          customSolution: `${Config.homeURL}/customsolutions`,
          axon: `${Config.homeURL}/home/requestapiaccess`,
          staffing: Config.staffingURL
        },
        insights: insightsData
    }
    return (
        <>
            <UniversalNavV2 {...args} menuClickCallback= { menuClickCallback } />
            {isViewAsModalOpen && <ViewAs setViewAsModalOpen={setViewAsModalOpen} />}
        </>
    )
}

export default SideNav